body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #1d1e26;
}

.App {
  margin: 40px auto;
  width: 650px;
}
.drac-card-subtle{
  box-shadow: 9px 9px 20px #191919,
      -2px -2px 15px #292929;
  border: none;
}

@media (max-width: 650px) {
  .App {
    width: 90%;
  }
}

.drac-card span {
  width: 100%;
  display: block;
}

.drac-btn {
  /* react-native  */
  color: black;
}

.centered {
  text-align: center;
}

.flex-cotainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}

.grafico {
  width: 100%;
  min-height: 500px;
}

.inputButton {
  flex: 1;
}

.inputName {
  flex: 5;
}

.inputButton button {
  width: 80%;
}

.container {
  width: 50%;
  margin: 40px auto;
}

.bag {
  margin-top: -20px;
  float: right;
  padding: 0px !important;
}

@media (max-width: 600px) {
  .bag {
    margin-top: 10px;
    margin-right: -20px;
  }

  .container {
    width: 90%;
    margin: 40px auto;
  }
}
